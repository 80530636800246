<template>
  <span class="square-icon">
    <img
      v-if="id === 'telegram'"
      class="square-icon__pic"
      src="../../assets/img/telegram-icon.svg"
      height="24px"
      width="auto"
    />
    <v-icon v-else color="white">mdi-{{ id }}</v-icon>
  </span>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
