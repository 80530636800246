<template>
  <div class="profile-social-accounts">
    <ul class="profile-social__list list-unstyled">
      <li class="profile-social__list__item" v-for="provider in providers" :key="provider.id">
        <template v-if="provider.value">
          <ng-text-field filled readonly :value="provider.value">
            <template v-slot:prepend>
              <SocialAccountIcon :id="provider.id" />
            </template>
            <template v-if="provider.unlinkUrl" v-slot:append-outer>
              <ng-button :href="provider.unlinkUrl" depressed>
                {{ $i18n.t('profile.unlink') }}
              </ng-button>
            </template>
          </ng-text-field>
        </template>
        <template v-else>
          <SocialAccountIcon :id="provider.id" />
          <ng-button
            v-if="provider.id === 'telegram'"
            :loading="isTelegramLinkLoading"
            class="ml-2"
            depressed
            @click="linkTelegram"
          >
            {{ $i18n.t('profile.link') }}
          </ng-button>
          <ng-button v-else class="ml-2" :href="provider.linkUrl" depressed>
            {{ $i18n.t('profile.link') }}
          </ng-button>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { fireError } from '@ngservices_front/services/errorService';
import SocialAccountIcon from './SocialAccountIcon.vue';
import { getIntegrationShortened } from '@/api/integrationApi';

export default {
  components: { SocialAccountIcon },
  data() {
    return {
      providers: window.socialProviders,
      isTelegramLinkLoading: false,
    };
  },
  methods: {
    async linkTelegram() {
      try {
        this.isTelegramLinkLoading = true;
        const { redirect_url } = await getIntegrationShortened('telegram');
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = redirect_url;
        a.click();
      } catch (e) {
        fireError(e);
      } finally {
        this.isTelegramLinkLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
